import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { SubscriberBase } from "app/common/base/subscriber.base";
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { ConfigService } from './admin/config/config.service';
import { DashboardService } from 'app/dashboard/dashboard.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent extends SubscriberBase implements OnInit {

  appTitle: string = 'MGIS Now';

  public constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private configService: ConfigService,
    private router: Router,
    private title: Title,
    private dashboardService: DashboardService
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(data => {
      if (data && data.pageTitle) {
        this.title.setTitle(`${data.pageTitle} | ${this.appTitle}`);
      } else {
        this.title.setTitle(this.appTitle);
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');

        this.authService.queryCurrentUser().subscribe(user => {
          (<any>window).ga('send', 'event', {
            eventCategory: 'User Page View',
            eventLabel: event.url,
            eventAction: `"${event.url}" viewed by: ${user ? user.Email : "Not Logged In"}`
          });
        });
      }
    });
      this.configService.getConfig(true).subscribe();
      this.dashboardService.getBuildNumber().subscribe(buildURI => this.checkVersionAndClearCookies(buildURI));

    }

    private checkVersionAndClearCookies(currentVersion: string): void {
        const storedVersion = localStorage.getItem('appVersion');
        console.log(`Stored version: ${storedVersion}, Current version: ${currentVersion}`);
        if (!storedVersion || storedVersion !== currentVersion) {
            // Versions differ => new deploy => clear cookies
            this.clearAllCookies();

            // Update stored version
            localStorage.setItem('appVersion', currentVersion);
        }
    }

    private clearAllCookies(): void {
        // Split document.cookie on ';' to get all cookies
        var cookie = document.cookie.split(';');

        for (var i = 0; i < cookie.length; i++) {

            var chip = cookie[i],
                entry = chip.split("="),
                name = entry[0];

            document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }

        console.log(`Cookies cleared`);
    }
}
