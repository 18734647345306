import { Component, OnInit } from "@angular/core";
import { SubscriberBase } from "../../../common/base/subscriber.base";
import { GlobalInfoService } from "../../../common/services/global-info.service";
import { AuthService } from "../../../auth/auth.service";
import { DashboardService } from "../../../dashboard/dashboard.service";
import { MGISConstants } from "../../../common/constants/mgis.constants";
import { LocalStorageService } from "angular-2-local-storage";
import { LayoutService } from "../layout.service";
import { Permissions } from "app/auth/permissions";
import { ConfigService } from "app/admin/config/config.service";
import { BillingService } from "app/billing/billing.service";

declare var moment: any;

@Component({

    selector: 'sa-navigation',
    styleUrls: ['./navigation.component.less'],
    templateUrl: './navigation.component.html',
})
export class NavigationComponent extends SubscriberBase implements OnInit {

    isMGISSuperUser = false;
    isMGISAccountManager = false;
    isMGISBrokerServices = false;
    isGroupBenefitsAdmin = false;
    isSurplusLinesAdmin = false;
    isSurplusLinesApplicant = false;
    isSurplusLinesBusinessContact = false;
    isSurplusLinesInsured = false;
    isSurplusLinesPolicyOwner = false;
    isProducerAdmin = false;
    isMedTravelApplicant = false;

    isMGISAdmin: boolean;
    isSurplusLinesPortal: boolean;
    isGroupBenefitsPortal: boolean;
    isProducerPortal: boolean;

    isNotSelfAdmin: boolean;

    canViewProducers: boolean;
    canManageProducers: boolean;

    canViewClients: boolean;
    canViewGroupBenefitsUsers: boolean;
    canViewMembers: boolean;
    canManageMembers: boolean;
    canViewBillingStatements: boolean;
    canViewBillingPayments: boolean;

    canViewProducerReports: boolean;
    canViewCommissionStatements: boolean;
    canViewTaxDocuments: boolean;
    canViewProducerUsers: boolean;

    canManageProducerBanking: boolean;

    isIndependentAgent: boolean = true;
    agentTypeCode: string = '';

    hasBillingStatements: boolean = false;

    menuBadges: any;
    asideInfo: any;

    opportunities: number = 0;

    todayMinus30: string = moment().subtract(30, 'days').format('MM/DD/YYYY');
    today: string = moment().format('MM/DD/YYYY');

    allowMedTravel: boolean;

    constructor(
        private globalInfoService: GlobalInfoService,
        private authService: AuthService,
        private configService: ConfigService,
        private dashboardService: DashboardService,
        private layoutService: LayoutService,
        private mgisConstants: MGISConstants,
        private store: LocalStorageService,
        private billingService: BillingService
    ) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(this.authService.getSelectedClient().subscribe(client => {
            if (client) {
                this.subscriptions.push(this.dashboardService.getGroupBenefitsInfo(client.AccountNum).subscribe(info => {
                    this.isNotSelfAdmin = info && !info.SelfAdmin;
                    this.canViewBillingStatements =(this.authService.userHasPermission(Permissions.GroupBenefits.Billing.Statements) || this.authService.userHasPermission(Permissions.Producers.Billing.Statements) || this.authService.userHasPermission(Permissions.HighLimits.Billing.Statements)) && this.isNotSelfAdmin;
                }));
            }
        }));

        this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.MGISSuperUser).subscribe(isInRole => this.isMGISSuperUser = isInRole));
        this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.MGISAccountManager).subscribe(isInRole => this.isMGISAccountManager = isInRole));
        this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.MGISBrokerServices).subscribe(isInRole => this.isMGISBrokerServices = isInRole));
        this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.GroupBenefitsAdmin).subscribe(isInRole => this.isGroupBenefitsAdmin = isInRole));
        // this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.SurplusLinesAdmin).subscribe(isInRole => this.isSurplusLinesAdmin = isInRole));
        this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.SurplusLinesApplicant).subscribe(isInRole => this.isSurplusLinesApplicant = isInRole));
        this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.SurplusLinesBusinessContact).subscribe(isInRole => this.isSurplusLinesBusinessContact = isInRole));
        this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.SurplusLinesInsured).subscribe(isInRole => this.isSurplusLinesInsured = isInRole));
        this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.SurplusLinesPolicyOwner).subscribe(isInRole => this.isSurplusLinesPolicyOwner = isInRole));
        this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.ProducerAdmin).subscribe(isInRole => this.isProducerAdmin = isInRole));
        //this.subscriptions.push(this.authService.queryCurrentUserRole(this.mgisConstants.Roles.MedTravelApplicant).subscribe(isInRole => this.isMedTravelApplicant = isInRole));

        this.canViewProducers = this.authService.userHasPermission(Permissions.Producers.Agents.View);
        this.canManageProducers = this.authService.userHasPermission(Permissions.Producers.Agents.Manage);
        this.canViewClients = this.authService.userHasPermission(Permissions.Producers.Clients.View);

        this.canViewGroupBenefitsUsers = this.isMGISSuperUser;
        // this.canViewGroupBenefitsUsers = this.authService.userHasPermission(Permissions.GroupBenefits.Users.Manage); TODO make users other than super users able to see this screen once it is feature complete
        this.canViewMembers = this.authService.userHasPermission(Permissions.GroupBenefits.Members.View);
        this.canManageMembers = this.authService.userHasPermission(Permissions.GroupBenefits.Members.Manage);
        this.canViewBillingPayments = this.authService.userHasPermission(Permissions.GroupBenefits.Billing.Payments) || this.authService.userHasPermission(Permissions.Producers.Billing.Payments) || this.authService.userHasPermission(Permissions.HighLimits.Billing.Payments);

        this.canViewProducerReports = this.authService.userHasPermission(Permissions.Producers.Reporting.Manage);
        this.canViewCommissionStatements = this.authService.userHasPermission(Permissions.Producers.CommissionStatements.View);
        this.canViewTaxDocuments = this.authService.userHasPermission(Permissions.Producers.TaxDocuments.View);
        this.canViewProducerUsers = this.authService.userHasPermission(Permissions.Producers.Users.Manage);

        this.canManageProducerBanking = this.authService.userHasPermission(Permissions.Producers.Banking.Manage);

        

        this.configService
            .getConfig()
            .subscribe(config => {
                this.allowMedTravel = config.AllowMedTravel;
            });

        this.subscriptions.push(this.globalInfoService.getBadges().subscribe(badges => this.menuBadges = badges));
        this.subscriptions.push(this.globalInfoService.getInfo().subscribe(info => this.asideInfo = info));

        this.authService.getPortal().subscribe(portal => {
            this.isMGISAdmin = portal == this.mgisConstants.Portals.MGISAdmin.Name;
            this.isGroupBenefitsPortal = portal == this.mgisConstants.Portals.GroupBenefits.Name;
            this.isSurplusLinesPortal = portal == this.mgisConstants.Portals.SurplusLines.Name;
            this.isProducerPortal = portal == this.mgisConstants.Portals.Producer.Name;

            if (this.isProducerPortal) {
                this.authService.getSelectedProducer().subscribe(firm => {
                    this.dashboardService.getProducerInfo(firm.FirmNum).subscribe(info => {
                        if (!info) {
                            return;
                        }
                        this.isIndependentAgent = info && info.IndependentAgent;
                        this.agentTypeCode = info && info.AgentTypeCode;
                    });
                });
            }

            if (this.isSurplusLinesPortal) {
                this.dashboardService.getSurplusLinesInfo().subscribe(info => {
                    let accountNum = info.AccountNum;
                    if(!accountNum) {
                        accountNum = '0'
                    }
                    this.billingService.getBills(accountNum).subscribe(bills => {
                        this.hasBillingStatements = !!bills.length;
                    });
                });
            }
        });

    }

    toggleMenu() {
        this.layoutService.onCollapseMenu();
    }

}